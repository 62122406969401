const en = {
    LOADING: 'Loading...',
    SUB_TEXT: 'Subtitle Text',
    OPEN_VIDEO: 'Open Video',
    OPEN_SUB: 'Open Subtitle',
    EXPORT_ASS: 'Export ASS',
    EXPORT_SRT: 'Export SRT',
    EXPORT_VTT: 'Export VTT',
    EXPORT_VIDEO: 'Export Video',
    TRANSLATE: 'Translation',
    TRANSLATING: 'Translation...',
    TRANSLAT_SUCCESS: 'Translation success',
    DECODE_START: 'Start decoding audio',
    DECODE_SUCCESS: 'Decoding audio success',
    DECODE_ERROR: 'Decoding audio failed',
    VIDEO_EXT_ERR: 'Unable to open this video format',
    SUB_EXT_ERR: 'Unable to open this subtitle format',
    MOBILE_TIP: 'Please use computer access',
    HOTKEY_01: 'Space: Play / Pause',
    HOTKEY_02: 'Ctrl + Z: Undo',
    CLEAR: 'Clear',
    UNDO: 'Undo',
    DELETE: 'Delete Subtitle',
    MERGE: 'Merge Next',
    SPLIT: 'Split Subtitle',
    LOADING_FFMPEG: 'Loading dependency...',
    LOADING_FONT: 'Loading font...',
    LOADING_VIDEO: 'Loading video...',
    LOADING_SUB: 'Loading subtitle...',
    CLEAR_TIP: 'Confirm that all data is cleared',
    BURN_ERROR: 'Burn subtitles failed',
    BURN_START: 'Start burn subtitles',
    BURN_SUCCESS: 'Burn subtitles success',
    OPEN_VIDEO_ERROR: 'Please open the video first',
};

const zh = {
    LOADING: '加载中...',
    SUB_TEXT: '字幕文本',
    OPEN_VIDEO: '打开视频',
    OPEN_SUB: '打开字幕',
    EXPORT_ASS: '导出 ASS',
    EXPORT_SRT: '导出 SRT',
    EXPORT_VTT: '导出 VTT',
    EXPORT_VIDEO: '导出视频',
    TRANSLATE: '批量翻译',
    TRANSLATING: '翻译中...',
    TRANSLAT_SUCCESS: '翻译成功',
    DECODE_START: '开始解码音频',
    DECODE_SUCCESS: '解码音频成功',
    DECODE_ERROR: '解码音频失败',
    VIDEO_EXT_ERR: '无法打开该视频格式',
    SUB_EXT_ERR: '无法打开该字幕格式',
    MOBILE_TIP: '请使用电脑访问',
    HOTKEY_01: '空格键: 播放 / 暂停',
    HOTKEY_02: 'Ctrl + Z: 撤销',
    CLEAR: '清空',
    UNDO: '撤销',
    DELETE: '删除字幕',
    MERGE: '合并下一个',
    SPLIT: '拆分字幕',
    LOADING_FFMPEG: '加载依赖中...',
    LOADING_FONT: '加载字体中...',
    LOADING_VIDEO: '加载视频中...',
    LOADING_SUB: '加载字幕中...',
    CLEAR_TIP: '确认清空所有数据吗',
    BURN_ERROR: '合成字幕失败',
    BURN_START: '开始合成字幕',
    BURN_SUCCESS: '合成字幕成功',
    OPEN_VIDEO_ERROR: '请先打开视频',
};

const i18n = {
    en,
    zh,
    'zh-cn': zh,
    'zh-tw': zh,
};

export default i18n;
